<template>
	<div class="flx-1 dis-flex-com ju-sb pd-x-20 h-100">
		<div class="pd-t-30">
			<div class="pd-t-30 ft-sz-24">
				<div class="">
					您好，
				</div>
				<div class="">
					请选择您要进入的公司
				</div>
			</div>
			<div class="pd-t-30">
				<div v-for="(item,index) in company_list" class="pd-x-15 pd-t-15 pd-b-15 dis-flex-row al-c mg-t-15 bd-r-5 ft-sz-16" style="color:#454545;"
				:style="companyid == item.companyid ? 'border: 1px solid #fc5753;color:#fc5753;' : 'background-color: #f8f8f8;border: 1px solid #f8f8f8;'"
				@click="radioClick(item.companyid)">
					<div class="flx-1">
						{{item.companyname}}
					</div>
					<div class="mg-l-auto dis-flex-row bd-r-b50" style="width: 24px;height: 24px;padding: 2px;" :style="companyid == item.companyid ? 'background-color: #ffffff;border: 1px solid #fc5753;':'border: 1px solid #666666;'">
						<div class="flx-1" style="border-radius: 50%;" :style="companyid == item.companyid ? 'background-color: #fc5753;':''">

						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mg-t-30" style="margin-bottom:50px;">
			<button class="pd-y-10 w-100 ft-co-ff ft-sz-16" type="button" @click="submintForm">确认</button>
		</div>
	</div>
</template>
<style scoped>
	button {border: none;color: #FFFFFF;border-radius: 30px;background: -webkit-linear-gradient(#f7514d, #fd704d);}
	button:focus {outline: none !important;}
</style>
<script>
	export default {
		name: 'companyList',
		props:{
			company_list:{
				type:Array,
				default:[]
			}
		},
		data() {
			return {
				companyid:''
			}
		},
		created(){},
		mounted() {},
		methods: {
			submintForm(){
				localStorage.setItem('companyid',this.companyid)
				this.$parent.switchCompanyList()
			},
			radioClick(val){
				this.companyid = val;
			}
		}
	}
</script>
