<template>
  <div id="app">
	 <!-- <transition :name="transitionName"> -->
		<router-view v-if="isRouterAlive"/>
	<!-- </transition> -->
    
  </div>
</template>
<script>
	export default {
		name: 'Login',
		 provide(){
		    return{
		      reload:this.reload
		    }
		},
		data() {
			return {
				transitionName:'',
				isRouterAlive:true,
			}
		},
		mounted() {},
		methods: {
			reload (){
			   this.isRouterAlive = false
			   this.$nextTick(function(){
				  this.isRouterAlive = true
			   })
			}
		}
	}
</script>
<style>
	@import url("../assets/font/iconfont.css");
	*{margin: 0;padding: 0;}
	html,body{height: 100%;}
	.w-100 {width: 100%;}
	#app,.h-100 {height: 100%;}
	.dis-flex-row {display: flex;-moz-display: flex;-webkit-display: flex;flex-direction: row;-moz-flex-direction: row;-webkit-flex-direction: row;}
	.dis-flex-com {display: flex;-moz-display: flex;-webkit-display: flex;flex-direction: column;-moz-flex-direction: column;-webkit-flex-direction: column;}
	.ov-fl-h{ overflow: hidden;text-overflow:ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;/**/}
	.al-c {align-items: center;-webkit-align-items: center;-moz-align-items: center;}
	.al-b {align-items: flex-end;-webkit-align-items: flex-end;-moz-align-items: flex-end;}
	.ju-c {justify-content: center;-webkit-justify-content: center;-moz-justify-content: center;}
	.ju-sb{justify-content: space-between;-webkit-justify-content: space-between;-moz-justify-content: space-between;}
	.ju-sa{justify-content: space-around;-webkit-justify-content: space-around;-moz-justify-content: space-around;}
	.flx-1 {flex: 1;-webkit-flex: 1;-moz-flex: 1;}
	.flx-2 {flex: 2;-webkit-flex: 2;-moz-flex: 2;}
	.flex-wrap{flex-wrap: wrap;}
	.txt-l {text-align: left !important;}
	.txt-r {text-align: right !important;}
	.txt-c {text-align: center !important;}
	.bd-bott {border-bottom: 1px solid #F5F5F5;}
	.mg-l-auto {margin-left: auto;}
	.mg-r-auto {margin-right: auto;}
	.po-r {position: relative;}
	.po-a {position: absolute;}
	.po-f {position: fixed;}
	
	.pd-10{padding:10px;}
	.pd-15{padding: 15px;}
	.pd-20{padding: 20px;}
	.pd-t-5{padding-top: 5px !important;}
	.pd-t-10{padding-top: 10px !important;}
	.pd-t-15{padding-top: 15px !important;}
	.pd-t-20{padding-top: 20px !important;}
	.pd-t-30{padding-top: 30px !important;}
	.pd-b-5{padding-bottom: 5px !important;}
	.pd-b-10{padding-bottom: 10px !important;}
	.pd-b-15{padding-bottom: 15px !important;}
	.pd-b-30{padding-bottom: 30px !important;}
	.pd-x-5{padding: 0 5px;}
	.pd-x-10{padding: 0 10px;}
	.pd-x-15{padding: 0 15px;}
	.pd-x-20{padding: 0 20px;}
	.pd-x-25{padding: 0 25px;}
	.pd-y-5{padding: 5px 0;}
	.pd-y-10{padding: 10px 0;}
	.pd-y-15{padding: 15px 0;}
	.mg-t-5{margin-top: 5px !important;}
	.mg-t-10{margin-top: 10px !important;}
	.mg-t-15{margin-top: 15px !important;}
	.mg-t-30{margin-top: 30px !important;}
	.mg-b-10{margin-bottom: 10px !important;}
	.mg-b-15{margin-bottom: 15px !important;}
	.mg-b-30{margin-bottom: 30px !important;}
	.mg-l-5{margin-left: 5px !important;}
	.mg-l-10{margin-left: 10px !important;}
	.mg-l-15{margin-left: 15px !important;}
	.mg-l-20{margin-left: 20px !important;}
	.mg-r-5{margin-right: 5px !important;}
	.mg-r-10{margin-right: 10px !important;}
	.mg-r-15{margin-right: 15px !important;}
	.mg-r-20{margin-right: 20px !important;}
	
	.ft-sz-10{font-size: 10px !important;}
	.ft-sz-12{font-size: 12px !important;}
	.ft-sz-14{font-size: 14px !important;}
	.ft-sz-16{font-size: 16px !important;}
	.ft-sz-18{font-size: 18px !important;}
	.ft-sz-20{font-size: 20px !important;}
	.ft-sz-22{font-size: 22px !important;}
	.ft-sz-24{font-size: 24px !important;}
	.ft-sz-26{font-size: 26px !important;}
	.ft-sz-28{font-size: 28px !important;}
	.ft-sz-30{font-size: 30px !important;}
	.ft-sz-32{font-size: 32px !important;}
	.ft-sz-34{font-size: 34px !important;}
	.ft-sz-36{font-size: 36px !important;}
	.ft-sz-38{font-size: 38px !important;}
	
	.ft-le-10{letter-spacing:2px;}
	
	.ft-co-ff{color: #ffffff;}
	.ft-co-f5{color: #F5F5F5;}
	.ft-co-99{color: #999999;}
	.ft-w-4{font-weight: 400;}
	.ft-w-b{font-weight: bold;}
	.ft-co-a3{color: #A3A3A3;}

	.bk-co-ff{background-color: #ffffff;}
	.bk-co-f5{background-color: #f5f5f5;}
	.bk-co-99{background-color: #d9d9d9;}
	
	.bd-r-5{border-radius: 5px;}
	.bd-r-10{border-radius: 10px;}
	.bd-r-30{border-radius: 30px;}
	.bd-r-50{border-radius: 50px;}
	.bd-r-b50{border-radius: 50%;}
	.bd-bl-r-10{border-bottom-left-radius: 10px;}
	.bd-br-r-10{border-bottom-right-radius: 10px;}
	/*根据项目后加*/
	.tab-h{height: 50px;}
	.ft-co-39{color: #3390ff;}
	.ft-co-fd{color: #fd6450;}
	.bk-co-39{background-color: #3390FF;}
	.bk-co-66{background-color: #666666;}
	.bk-co-fd{background-color: #fd6450;}
	.bk-co-47{background-color: #4478FF;}
	.bk-co-fd4{background-color: #fdb4aa;}
	.img-tou {width: 58px;height: 58px;}
	.img-tou1 {width: 46px;height: 46px;}
	.img_icon {width: 20px;}
	.mobile-fd{color: #fd6450 !important;}
	input {border: none !important;/* border-bottom: 1px solid #F5F5F5 !important; */outline: none !important;font-size: 16px !important;height: 24px !important;}
	input:focus {outline: none !important;}
	/* WebKit browsers */
	input::-webkit-input-placeholder {color: #C0C0C0;font-size: 16px;}
	/* Mozilla Firefox 4 to 18 */
	input:-moz-placeholder {color: #C0C0C0;opacity: 1;font-size: 16px;}
	/* Mozilla Firefox 19+ */
	input::-moz-placeholder {color: #C0C0C0; opacity: 1; font-size: 16px;}
	/* Internet Explorer 10+ */
	input:-ms-input-placeholder {color: #C0C0C0;font-size: 16px;
	}
	.loginCode-class{width: 120px;}
	.loginCode-class>input{width: 140px;}
	.van-icon{font-size: 12px !important;}
	.tabbarheight{padding: 25px;}
	.statusIcon{top: -25px;right: 5px;font-size: 44px;}
</style>
