import axios from 'axios'
import {
	zmitiConfig
} from './config'
import router from '@/router'
import md5 from 'js-md5'
import * as Base64 from 'js-base64'
import {Notify} from 'vant';
// axios.defaults.withCredentials = true;
/*设置默认的请求超时时间*/
axios.defaults.timeout = 10000;

// 请求拦截器, axios sql加密
axios.interceptors.request.use((config) => {
	/*获取token*/
	let user = {};
	if(localStorage.getItem('user')){
		user = JSON.parse(localStorage.getItem('user'));
		user.token && (config.headers.Authorization = user.token);
	}
	
	// //传递的参数都进行加密
	// let rs = ''
	//     if (config.data) {
	//         rs = encrypt(config.data)   
	//         config.data = rs.encoded
	//     } else if (config.params) {
	//         rs = encrypt(config.params)
	//         config.params = { param: rs.encoded }
	//     }      
	//表头添加签名牌,后台拿到后按照统一的规则机密后验证是否一致,一致进行下一步
	// config.headers.signature = rs.md5Str
	
	// config.data = JSON.stringify(config.data);
	config.headers = {
		'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
	}
	return config
}, (error) => {
	return Promise.reject(error)
})
// 响应拦截器
axios.interceptors.response.use(
	response => {
		//拦截响应，做统一处理 
		if (response.data.getret === 9997) {
			let miao = 3;
			let interval = setInterval(() => {
				Notify({ type: 'warning', message: '需要登录才可以查看，' + miao + '秒后跳转到登录！', duration: 1000 });
				miao--;
				if (miao == 0) {
					clearInterval(interval);
				}
			}, 1000);
			setTimeout(() => {
				router.push('/login?redirect=' + router.path);
			}, 6000)
		} else {
			return Promise.resolve(response);
		}
	},
	// 服务器状态码不是2开头的的情况
	// 这里可以跟你们的后台开发人员协商好统一的错误状态码    
	// 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
	// 下面列举几个常见的操作，其他需求可自行扩展
	error => {
		return Promise.reject(error.response.status) // 返回接口返回的错误信息
	}
);


/**
 * axios请求
 * @param {Object} conf [请求的url地址及请求方式]
 * @param {Object} params [请求时携带的参数]
 */
export function apiIos(conf, params) {
	let user = {};
	if(localStorage.getItem('user')){
		user = JSON.parse(localStorage.getItem('user'));
	}
	params = Object.assign(params, {
		action: conf.action,
		"ui": {
			"userid": user.userid,
			"token": user.token,
		}
	})
	let url = zmitiConfig.H5API + '?name=' + conf.name;
	if (conf.type == 'get') {
		return new Promise((resolve, reject) => {
			axios.get(url, {
				params: params
			}).then(res => {
				resolve(res.data);
			}).catch(err => {
				reject(err.data)
			})
		});
	}
	if (conf.type == 'post') {
		return new Promise((resolve, reject) => {
			axios.post(url, params)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err.data)
				})
		});
	}
}


function encrypt(params) {
	let encoded = Base64.encode(JSON.stringify(params)) //字符串转义,加密
	let md5Str = md5(md5(encoded) + '!@#$%^&') //和后台约定好的签名
	return {
		encoded,
		md5Str
	}
}