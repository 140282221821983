import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'

Vue.use(VueRouter)

const Router = new VueRouter({
	routes: [{
			path: '/',
			name: 'Home',
			component: Home,
			meta: {
				auth: true,/*是否需要登录*/
				hidden: true,
				title: "逑索云",
				keepAlive: false,
			}
		},{
			path: '/information',
			name: 'Information',
			component: () => import( /* webpackChunkName: "Home" */ '../views/Home/information.vue'),
			meta: {
				auth: true,/*是否需要登录*/
				hidden: true,
				title: "资讯",
				keepAlive: false,
			}
		},{
			path: '/service',
			name: 'Service',
			component: () => import( /* webpackChunkName: "Home" */ '../views/Home/service.vue'),
			meta: {
				auth: true,/*是否需要登录*/
				hidden: true,
				title: "服务",
				keepAlive: false,
			}
		},{
			path: '/my',
			name: 'My',
			component: () => import( /* webpackChunkName: "Home" */ '../views/Home/my.vue'),
			meta: {
				auth: true,/*是否需要登录*/
				hidden: true,
				title: "个人中心",
				keepAlive: false,
			}
		},{
			path: '/personal',
			name: 'Personal',
			component: () => import( /* webpackChunkName: "Home" */ '../views/Home/personal.vue'),
			meta: {
				auth: true,/*是否需要登录*/
				hidden: true,
				title: "我的",
				keepAlive: false,
			}
		},{
			path: '/activity',
			name: 'Activity',
			component: () => import( /* webpackChunkName: "HuoDongJu" */ '../views/HuoDongJu/activityList.vue'),
			meta: {
				auth: false,
				hidden: true,
				title: "活动",
				keepAlive: false,
			}
		},{
			path: '/activity/detail/:id',
			name: 'ActivityDetail',
			component: () => import( /* webpackChunkName: "HuoDongJu" */ '../views/HuoDongJu/detail/activity.vue'),
			meta: {
				auth: false,
				hidden: true,
				title: "活动详情",
				keepAlive: false,
			}
		},{
			path: '/evaluationStar',
			name: 'EvaluationSstar',
			component: () => import( /* webpackChunkName: "HuoDongJu" */ '../views/HuoDongJu/evaluationStarList.vue'),
			meta: {
				auth: false,
				hidden: true,
				title: "评星",
				keepAlive: false,
			}
		},{
			path: '/evaluationStar/detail/:id',
			name: 'evaluationStarDetail',
			component: () => import( /* webpackChunkName: "HuoDongJu" */ '../views/HuoDongJu/detail/evaluationStar.vue'),
			meta: {
				auth: false,
				hidden: true,
				title: "评星详情",
				keepAlive: false,
			}
		},{
			path: '/form',
			name: 'Form',
			component: () => import( /* webpackChunkName: "HuoDongJu" */ '../views/HuoDongJu/formList.vue'),
			meta: {
				auth: false,
				hidden: true,
				title: "表单",
				keepAlive: false,
			}
		},{
			path: '/form/detail/:id',
			name: 'formDetail',
			component: () => import( /* webpackChunkName: "HuoDongJu" */ '../views/HuoDongJu/detail/formDetail.vue'),
			meta: {
				auth: false,
				hidden: true,
				title: "表单详情",
				keepAlive: false,
			}
		},{
			path: '/survey',
			name: 'Survey',
			component: () => import( /* webpackChunkName: "HuoDongJu" */ '../views/HuoDongJu/surveyList.vue'),
			meta: {
				auth: false,
				hidden: true,
				title: "调查",
				keepAlive: false,
			}
		},{
			path: '/survey/detail/:id',
			name: 'surveyDetail',
			component: () => import( /* webpackChunkName: "HuoDongJu" */ '../views/HuoDongJu/detail/survey.vue'),
			meta: {
				auth: false,
				hidden: true,
				title: "调查详情",
				keepAlive: false,
			}
		},{
			path: '/vote',
			name: 'Vote',
			component: () => import( /* webpackChunkName: "HuoDongJu" */ '../views/HuoDongJu/voteList.vue'),
			meta: {
				auth: false,
				hidden: true,
				title: "投票",
				keepAlive: false,
			}
		},{
			path: '/vote/detail/:id',
			name: 'voteDetail',
			component: () => import( /* webpackChunkName: "HuoDongJu" */ '../views/HuoDongJu/detail/vote.vue'),
			meta: {
				auth: false,
				hidden: true,
				title: "投票详情",
				keepAlive: false,
			}
		},{
			path: '/cyhome',
			name: 'HomeCy',
			component: () => import( /* webpackChunkName: "ChangYue" */ '../views/ChangYue'),
			meta: {
				auth: true,
				hidden: true,
				title: "畅阅",
				keepAlive: false,
			}
		},{
			path: '/cycomments/:manuscriptid?',
			name: 'CommentsCy',
			component: () => import( /* webpackChunkName: "ChangYue" */ '../views/ChangYue/comments.vue'),
			meta: {
				auth: true,
				hidden: true,
				title: "评论",
				keepAlive: false,
			}
		},{
			path: '/mycomments',
			name: 'MyComments',
			component: () => import( /* webpackChunkName: "ChangYue" */ '../views/ChangYue/myComments.vue'),
			meta: {
				auth: true,
				hidden: true,
				title: "我的评论",
				keepAlive: false,
			}
		},{
			path: '/cystatistical/:productid?',
			name: 'StatisticalCy',
			component: () => import( /* webpackChunkName: "ChangYue" */ '../views/ChangYue/statistical.vue'),
			meta: {
				auth: true,
				hidden: true,
				title: "统计",
				keepAlive: false,
			}
		}, {
			path: '/login',
			name: 'Login',
			component: () => import( /* webpackChunkName: "Login" */ '../views/Login/index'),
			meta: {
				auth: false,
				hidden: false,
				title: "登录",
				keepAlive: false
			}
		}, {
			path: '/wxIndex',
			name: 'Wxindex',
			component: () => import( /* webpackChunkName: "Login" */ '../views/Login/wxIndex.vue'),
			meta: {
				auth: false,
				hidden: false,
				title: "微信授权登录",
				keepAlive: false
			}
		},{
			path: '/modify/:type?',
			name: 'Modify',
			component: () => import( /* webpackChunkName: "Login" */ '../views/Login/modify.vue'),
			meta: {
				auth: false,
				hidden: true,
				title: "修改密码",
				keepAlive: false,
			}
		},{
			path: '/registered',
			name: 'Registered',
			component: () => import( /* webpackChunkName: "Login" */ '../views/Login/registered.vue'),
			meta: {
				auth: false,
				hidden: true,
				title: "注册",
				keepAlive: false,
			}
		},{
			path: '/detail/:id?/:companyid?/:productid?',
			name: 'Detail',
			component: () => import( /* webpackChunkName: "XinWen" */ '../views/XinWen/detail.vue'),
			meta: {
				auth: false,
				hidden: true,
				title: "详情",
				keepAlive: false,
			}
		},{
			path: '/monitor',
			name: 'Monitor',
			component: () => import( /* webpackChunkName: "monitor" */ '../views/Monitor/index.vue'),
			meta: {
				auth: true,/*是否需要登录*/
				hidden: true,
				title: "信息雷达",
				keepAlive: false,
			}
		}
	]
})
/*路由全局拦截*/
Router.beforeEach(function(to, from, next) {
	if (to.meta.title) {
		document.title = to.meta.title
	} else {
		document.title = '逑索云'
	}
	if (to.matched.length ===0) {/*如果未匹配到路由*/
	    from.name ? next('/'+from.name.toLowerCase()) : next('/');   /*如果上级也未匹配到路由则跳转首页页面，如果上级能匹配到则转上级路由*/
	  } else {
	    //需求登录判断
	    if (to.meta.auth) {
			/*不为空*/
				if(localStorage.getItem('user')){
	    		next();
	    	} else {
	    		next('/login');
	    		// 这里与上面的相对应，此处也可以写成
	    		/***  next({
	    		     path: '/login',
	    		      query: { redirect: to.fullPath }
	    		  })***/
	    		//上面为另外一种写法
	    	}
	    } else {
	    	next();
	    }
	  }
	
})
/*路由重写*/
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
export default Router
