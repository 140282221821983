let H5API = 'https://api.qiuso.com/';
// let H5API = '/api-qiuso';
let picHost = 'https://file.qiuso.com';
let pageHost = 'https://m.qiuso.com/';
let picurl = picHost + '/index/files/file?p=';
let serverProduct=[{
	'name':'活动聚',
	'icon':'&#xe69b;',
	'status':false,
	'productid':'',
	'list':[{
	  'title':'调查',
	  'icon':'&#xe69e;',
	  'status':false,
	  'urls':'/survey'/*列子：/pages/server/index*/
	},{
	  'title':'评星',
	  'icon':'&#xe645;',
	  'status':false,
	  'urls':'/evaluationStar'
	},{
	  'title':'投票',
	  'icon':'&#xe695;',
	  'status':false,
	  'urls':'/vote'
	},{
	  'title':'活动',
	  'icon':'&#xe6b1;',
	  'status':false,
	  'urls':'/activity'
	},{
	  'title':'表单',
	  'icon':'&#xe67d;',
	  'status':false,
	  'urls':'/form'
	}]
	},{
	'name':'出差宝',
	'icon':'&#xe6a8;',
	'status':false,
	'productid':'',
	'list':[{
	  'title':'出差标准查询',
	  'status':true,
	  'icon':'&#xe68e;',
	  'urls':''
	},{
	  'title':'机票火车',
	  'status':false,
	  'icon':'&#xe696;',
	  'urls':''
	},{
	  'title':'酒店预定',
	  'status':false,
	  'icon':'&#xe69d;',
	  'urls':''
	},{
	  'title':'订车',
	  'status':false,
	  'icon':'&#xe68f;',
	  'urls':''
	}]
	},{
	'name':'福利厅',
	'icon':'&#xe697;',
	'status':false,
	'productid':'',
	'list':[{
	  'title':'商城购物',
	  'status':true,
	  'icon':'&#xe699;',
	  'urls':''
	},{
	  'title':'蛋糕店',
	  'status':true,
	  'icon':'&#xe692;',
	  'urls':''
	},{
	  'title':'医疗体检',
	  'icon':'&#xe698;',
	  'urls':''
	},{
	  'title':'团队活动',
	  'status':true,
	  'icon':'&#xe694;',
	  'urls':''
	},{
	  'title':'用餐外卖',
	  'status':false,
	  'icon':'&#xe69f;',
	  'urls':''
	}]
	},{
	'name':'资讯窗',
	'icon':'&#xe69a;',
	'status':false,
	'productid':'',
	'list':[{
	  'title':'消息',
	  'status':true,
	  'icon':'&#xe69c;',
	  'urls':''
	},{
	  'title':'公告',
	  'status':true,
	  'icon':'&#xe6a7;',
	  'urls':''
	},{
	  'title':'通知',
	  'status':true,
	  'icon':'&#xe6b4;',
	  'urls':''
	},{
	  'title':'新闻',
	  'status':true,
	  'icon':'&#xe6b8;',
	  'urls':''
	},{
	  'title':'其他',
	  'status':true,
	  'icon':'&#xe695;',
	  'urls':''
	}]
	},{
	'name':'互动台',
	'icon':'&#xe6af;',
	'status':false,
	'productid':'',
	'list':[{
	  'title':'意见箱',
	  'status':true,
	  'icon':'&#xe6b2;',
	  'urls':''
	},{
	  'title':'分享会',
	  'status':true,
	  'icon':'&#xe6ab;',
	  'urls':''
	}]
	},{
	'name':'畅阅',
	'icon':'&#xe609;',
	'status':false,
	'productid':'',
	'list':[{
	  'title':'开始工作',
	  'status':true,
	  'icon':'&#xe667;',
	  'urls':'/cyhome'
	},{
	  'title':'统计',
	  'status':false,
	  'icon':'&#xe642;',
	  'urls':'/cystatistical'
	}]
	},{
	'name':'会议通',
	'icon':'&#xe6b1;',
	'status':false,
	'productid':'',
	'list':[{
	  'title':'会议室预定',
	  'status':true,
	  'icon':'&#xe6b5;',
	  'urls':''
	},{
	  'title':'会议活动',
	  'status':true,
	  'icon':'&#xe6b7;',
	  'urls':''
	}]
}]
export const zmitiConfig = {
	H5API,
	picurl,
	pageHost,
	serverProduct
}
